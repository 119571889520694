.ant-form > .ant-form-item {
  margin-bottom: 12px;
}
.bg-gray {
  background-color: #f0f0f0 !important;
}
.text-sm {
  font-size: smaller;
}
.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
