.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1 {
  font-size: 2.5rem;
}
.h2 {
  font-size: 2rem;
}
.h3 {
  font-size: 1.75rem;
}
.h4 {
  font-size: 1.5rem;
}
.h5 {
  font-size: 1.25rem;
}
.h6 {
  font-size: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #fa8c16 !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #fa8c16 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-5 {
  height: 5% !important;
}
.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}
.h-20 {
  height: 20% !important;
}
.h-25 {
  height: 25% !important;
}
.h-30 {
  height: 30% !important;
}
.h-35 {
  height: 35% !important;
}
.h-40 {
  height: 40% !important;
}
.h-45 {
  height: 45% !important;
}
.h-50 {
  height: 50% !important;
}
.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-75 {
  height: 75% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-95 {
  height: 95% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #fa8c16 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #d46b08 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
