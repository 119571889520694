.sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.logo {
  margin: 16px;
  text-align: center;
  color: #fff;
  font-weight: bolder;
}
.logo > img {
  width: 100%;
}
